import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Pagination from "../components/Pagination";
import DomainsView from "../components/DomainsView";
import Sectiontitle from "../components/Sectiontitle";
import Spinner from "../components/Spinner";

function Domains() {
  const [domains, setPortfoios] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [domainsPerPage] = useState(9);

  useEffect(() => {
    let mounted = true;
    axios.get("/api/domains").then((response) => {
      if (mounted) {
        setPortfoios(response.data);
      }
    });
    return () => (mounted = false);
  }, []);

  const indexOfLastDomains = currentPage * domainsPerPage;
  const indexOfFirstDomains = indexOfLastDomains - domainsPerPage;
  const currentDomains = domains.slice(
    indexOfFirstDomains,
    indexOfLastDomains
  );

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <Helmet>
        <title>Domains</title>
        <meta
          name="description"
          content="Emily Hoang Personal Domains Page"
        />
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
          <div className="container">
            <Sectiontitle title="Domains" />
            {<DomainsView domains={currentDomains} />}
            {!(domains.length > domainsPerPage) ? null : (
              <Pagination
                className="mt-50"
                itemsPerPage={domainsPerPage}
                totalItems={domains.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}
          </div>
        </div>
      </Suspense>
    </Layout>
  );
}

export default Domains;
