import Mock from "../mock";

const database = {
  information: {
    name: 'Emily Hoang',
    aboutContent1: "I am a software engineer who strives for code excellence and advocates for inclusive tech communities.",
    aboutContent2: "👨‍💻 By day, I immerse myself in the world of software development, crafting high-quality code and architecting solutions that make an impact.",
    aboutContent3: "🌟 In my free time, I'm committed to levelling the playing field in tech. I volunteer to mentor and support others from a diversed backgrounds, helping them break into the exciting world of technology. 🚀 Join me as I share stories, insights, and resources to inspire and guide aspiring tech enthusiasts.",
    aboutContent4: "🚀 Join me as I share stories, insights, and resources to inspire and guide aspiring tech enthusiasts.",
    age: 32,
    phone: '',
    nationality: 'Vietnamese, Australian',
    language: 'Vietnamese, English',
    email: 'nhung@nhunghoang.com',
    address: 'Melbourne, Victoria, Australia',
    freelanceStatus: 'Not Available',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'www.linkedin.com/in/emily-hoang-0788b19a',
      dribbble: '',
      github: 'https://github.com/emily-hoang'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/emily-hoang-cv.pdf'
  },
  services: [
    {
      title: "Web Development",
      icon: 'brush-alt',
      details: "Strong background in creating dynamic and responsive web applications. Skilled in front-end and back-end technologies, delivering user-friendly and high-performance solutions. Demonstrates a solid understanding of modern web frameworks and best practices. Committed to continuous learning, agile methodologies, and collaborative teamwork to deliver exceptional software solutions."
    },
    {
      title: "Backend Services Development",
      icon: 'code',
      details: "Specializing in building robust RESTful APIs, setting up comprehensive observability frameworks, and creating efficient build and development pipelines. Proficient in designing and implementing extensive test suites to ensure code quality and reliability. Demonstrates a strong commitment to best practices in software development, including continuous integration and deployment (CI/CD), and agile methodologies. Known for a detail-oriented approach and effective collaboration within cross-functional teams to deliver high-performance backend solutions."
    },
    {
      title: "AWS Solutions",
      icon: 'mobile',
      details: "Skilled in leveraging AWS services to optimize cloud infrastructure, enhance performance, and maintain robust security standards. Demonstrates expertise in implementing scalable and reliable solutions, adhering to industry best practices, and continuously improving cloud-based applications. Known for a meticulous approach and effective collaboration with cross-functional teams to deliver high-quality AWS solutions."
    }
  ],
  skills: [
    {
      title: "Programing Languages: Java, C#, Javascript, Typescript, React, Ruby",
      value: 95
    },
    {
      title: "AWS Services: VPC, NACL, SG, Private Link, EC2, ASG, S3, EFS, Glue, Kinesis, MSK, SNS, SQS, EKS, ECR, ECS, CloudWatch, CloudFormation, DMS, ELB, IAM, KMS, Kafka",
      value: 90
    },
    {
      title: "Databases: DynamoDB, Amazon RDS, MySQL, Aurora Serverless v2",
      value: 70
    },
    {
      title: "Observability Tools: Datadog, New Relic, Splunk, Sumo Logic",
      value: 85
    },
    {
      title: "Build & Deployment Pipelines: Git Actions, TeamCity, ArgoCD",
      value: 80
    }
  ],
  domains: [
    {
      id: 1,
      title: "Banking",
      subtitle: "Modernising A Critical Service In The Bank System",
      imageUrl: "/images/portfolio-banking.png",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: ''
    },
    {
      id: 2,
      title: "Airline",
      subtitle: "Enhancing System Observability And Performance",
      imageUrl: "/images/portfolio-airline.png",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: ''
    },
    {
      id: 3,
      title: "Australian Retail",
      subtitle: "Building a large-scale subscription platform.",
      imageUrl: "/images/portfolio-australian-retail.png",
      url: 'https://pinterest.com'
    },
    {
      id: 4,
      title: "Collaboration Software - SaaS",
      subtitle: "Cloud Migration & Database Migration.",
      imageUrl: "/images/portfolio-Collaboration-SaaS.png",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 5,
      title: "Healthcare",
      subtitle: "Enhancing & Expanding the capability of the Bahmni software.",
      imageUrl: "/images/portfolio-healthcare.png",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    },
    {
      id: 6,
      title: "Accounting Software - SaaS",
      subtitle: "Building a Proactive Communication Platform.",
      imageUrl: "/images/portfolio-Accounting-SaaS.png",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 7,
      title: "Online Retail",
      subtitle: "Enhancing the E-comerce capabilities of an established application.",
      imageUrl: "/images/portfolio-general-retail.png",
      largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
      url: 'https://twitter.com'
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "Feb 2024 - Present",
        position: "Full Stack Engineer",
        company: "SaaS Company",
        details: "I’m working on a project that enables the client to seamlessly transition their customers into using a modernised service that provides more functionalities with multiple robust back-end systems. This involves building a RESTful API following best practices, design principles, and comprehensive observability while integrating with various services, ensuring data consistency, and optimising performance to handle diverse client requirements. On the frontend, I focus on creating intuitive interfaces that interact efficiently with the API, ensuring a smooth user experience. By leveraging the latest technologies and best practices, I aim to deliver a scalable and maintainable solution that enhances overall system functionality and user satisfaction."
      },
      {
        id: 2,
        year: "July 2023 - September 2023",
        position: "DevOps Engineer",
        company: "Banking Company",
        details: "As part of this project, I focused on modernizing a critical service within a large banking system. My responsibilities include setting up and refining the new service by establishing a robust build and deployment pipeline, setting up infrastructure resources. I implemented a solution design that enhances system reliability and significantly reduces downtime, ensuring a consistent and reliable service. Additionally, I promoted a culture of knowledge sharing within the team and the organisation’s engineering community by documenting best practices and facilitating collaborative discussions, fostering an environment where continuous improvement and collective learning are prioritized."
      },
      {
        id: 3,
        year: "Feb 2023 - May 2023",
        position: "DevOps Engineer",
        company: "Airline Company",
        details: "My objective was enhancing system observability and performance. My focus was on establishing a robust monitoring infrastructure based on the four golden signals: latency, traffic, errors, and saturation. I implemented Application Performance Monitoring (APM) for multiple services to deliver clear and actionable insights into the systems behaviour, enabling proactive issue resolution and performance optimisation. Additionally, I built reusable Terraform modules to define and set Service Level Objectives (SLOs) to establish clear performance and reliability goals, ensuring alignment across teams and common alerts to provide early detection of issues before they impact users, allowing teams to address problems promptly and reduce downtime. To support these efforts, I wrote a comprehensive Observability Guide that serves as a foundational resource for over 100 services, standardising best practices and streamlining the observability setup."
      },
      {
        id: 4,
        year: "August 2021 - Feb 2022",
        position: "Full Stack Engineer",
        company: "Australian Retail Company",
        details: "I was part of a team that built a large-scale subscription platform for Australian customers. I contributed to developing the subscription service from the ground up, focusing on event-driven design and microservices architecture. My role involved designing and implementing functionalities for managing multiple subscription options and handling payment processing. Additionally, I played an important role in ensuring a smooth migration for existing customers to the new platform, facilitating a seamless transition and maintaining service continuity throughout the process."
      },
      {
        id: 5,
        year: "November 2020 - July 2021",
        position: "Infrastructure Engineer",
        company: "SaaS Company",
        details: "I contributed to the success of two significant migration projects. The first project involved migrating a critical service to the AWS cloud by enhancing the internal PaaS capabilities. Over the course of three months, hundreds of services was successfully migrated from their datacenter to AWS. This new capability created a new pathway for services who utilised this internal platform to be deployed into a shared and centralised managed cloud environment. The second project focused on seamlessly migrating a critical service’s database tables containing billions of records from AWS RDS to DynamoDB, ensuring that the transition was smooth and did not impact customer experience. These migrations not only improved scalability and efficiency but also paved the way for more streamlined cloud-based operations in the future."
      },
      {
        id: 6,
        year: "February 2020 - March 2020",
        position: "Back-end Engineer",
        company: "Healthcare Company",
        details: "I worked on integrating new functionalities into Bahmni - an open-source hospital management system designed to provide a comprehensive electronic medical record (EMR) and hospital information system (HIS) for low-resource environments. These new capabilities were built to support various COVID-19-related tasks amid the pandemic. This included developing features for testing, managing patient information (such as travel history), securely sharing data with relevant authorities and systems, and recording health data of COVID-19 patients. I also contributed to building the capability of facilitating video calls between patients and doctors through a lightweight, freely accessible application, improving remote healthcare access and communication."
      },
      {
        id: 7,
        year: "November 2019 - June 2020",
        position: "Full Stack Engineer",
        company: "Accounting Software Company",
        details: "I was part of a team that built a platform for delivering timely and impactful customer communications. My focus was mainly around creating solutions that drive behavioural change by using event-driven architecture. This included designing systems for personalized messaging, tracking customer interactions, and analyzing engagement data to optimize communication strategies. The platform aimed to enhance customer engagement and influence behaviour through targeted, timely, and relevant messages."
      },
      {
        id: 8,
        year: "October 2019 - November 2019",
        position: "Full Stack Engineer",
        company: "ThoughtWorks University",
        details: "As part of ThoughtWorks university, I worked on a simulated project to expand the feature set of an e-commerce website, I focused on enhancing user experience while addressing both security and functional issues of the existing application. My role involved developing new features such as streamlined navigation, personalized recommendations, and intuitive checkout processes. Concurrently, I implemented robust security measures to protect user data and prevent vulnerabilities. By balancing feature expansion with comprehensive security and performance improvements, I contributed to a more engaging and secure e-commerce platform."
      }
    ],
    educationExperience: []
  },
  blogs: [
    {
      id: 1,
      title: 'Part 1 — From Adversity To Algorithms: The Journey Of Emily Hoang',
      featuredImage: '/images/blog-image-emily-story-1.png',
      filesource: 'from-adversity-to-algorithms-part-1.md',
      createDay: "03",
      createMonth: 'October',
      createYear: "2023"
    },
    {
      id: 2,
      title: 'Part 2 — From Adversity To Algorithms: The Journey Of Emily Hoang',
      featuredImage: '/images/blog-image-emily-story-2.png',
      filesource: 'emily-blogs/from-adversity-to-algorithms-part-2.md',
      createDay: "03",
      createMonth: 'October',
      createYear: "2023"
    },
    {
      id: 3,
      title: 'Part 3 — From Adversity To Algorithms: The Journey Of Emily Hoang',
      featuredImage: '/images/blog-image-emily-story-3.png',
      filesource: 'from-adversity-to-algorithms-part-3.md',
      createDay: "03",
      createMonth: 'October',
      createYear: "2023"
    },
    {
      id: 4,
      title: 'Part 4 — From Adversity To Algorithms: The Journey Of Emily Hoang',
      featuredImage: '/images/blog-image-emily-story-4.png',
      filesource: 'from-adversity-to-algorithms-part-4.md',
      createDay: "04",
      createMonth: 'October',
      createYear: "2023"
    },
    {
      id: 5,
      title: 'Part 5 — From Adversity To Algorithms: The Journey Of Emily Hoang',
      featuredImage: '/images/blog-image-emily-story-5.png',
      filesource: 'from-adversity-to-algorithms-part-5.md',
      createDay: "04",
      createMonth: 'October',
      createYear: "2023"
    },
    {
      id: 6,
      title: 'Crafting Maintainable Code with SOLID Principles',
      featuredImage: '/images/blog-image-SOLID.png',
      filesource: 'software-design-with-SOLID-principles.md',
      createDay: "31",
      createMonth: 'October',
      createYear: "2023"
    },
    {
      id: 7,
      title: 'Pair Programming Unveiled: A Software Consultant\'s Perspective',
      featuredImage: '/images/blog-image-7.jpg',
      filesource: 'pair-programming.md',
      createDay: "05",
      createMonth: 'October',
      createYear: "2023"
    },
    {
      id: 8,
      title: 'AWS Summit Sydney 2024 — Exploring the Future of AI',
      featuredImage: '/images/blog-image-AWS-Summit.png',
      filesource: 'aws-summit.md',
      createDay: "09",
      createMonth: 'June',
      createYear: "2024"
    }
  ]
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/domains").reply(config => {
  const response = database.domains;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});